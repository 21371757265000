/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddContableAccount()"
          >Agregar cuenta contable</b-button
        >
      </div>

      <div class="control">
        <b-button
          type="is-primary"
          @click="startImport()"
          :loading="refreshLoading"
          >Importar cuentas contables</b-button
        >
      </div>

      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de cuentas contables: <b>{{ contableAccounts.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="contableAccounts"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        scrollable
        ref="contableAccounts"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CUENTA}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        default-sort="CUENTA"
      >
        <template #empty>
          <div class="has-text-centered">No hay cuentas contables</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span
              v-if="column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
            <span
              v-if="!column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <div class="content">
                    <AddContableAccount
                      :contableAccountInformation="props.row"
                    />
                  </div>
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";
import ImportFileTxtModal from "../../Global/components/ImportFileTxtModal.vue";
import AddContableAccount from "./AddContableAccount.vue";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ContableAccountsList",
  components: { AddContableAccount },
  directives: { cleave },
  data() {
    return {
      perPage: 25,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CUENTA",
          label: "Cuenta",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ACUMULA",
          label: "Acumula",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TIPO",
          label: "Tipo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ESTADO",
          label: "Estado",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      masks: Masks,
      moment: moment,
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  async mounted() {
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETCONTABLEACCOUNTS"));

    await Promise.all(allPromises);
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETCONTABLEACCOUNTS"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    showContableAccount(contableAccountInformation) {
      console.log(contableAccountInformation);
    },
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileTxtModal,
        props: {
          importType: "contableAccounts",
          nameImport: "Cuentas contables",
          action: "IMPORTCONTABLEACCOUNTS",
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddContableAccount() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddContableAccount,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    contableAccounts() {
      return this.$store.getters.CONTABLEACCOUNTS.map(
        (singleContableAccount) => {
          let singleContableAccountWithProps = { ...singleContableAccount };

          return singleContableAccountWithProps;
        }
      );
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
