<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!contableAccountInformation">
      Agregar Cuenta Contable
    </h1>

    <div v-if="!contableAccountInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cuenta (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': contableAccountRequiredErrors.CUENTA }"
        :message="{
          'La clave de la cuenta no es valida':
            contableAccountRequiredErrors.CUENTA,
        }"
      >
        <b-input
          placeholder="Ejemplo: 60000900002"
          expanded
          icon="numeric-1-circle-outline"
          v-model="newContableAccount.CUENTA"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Descripción (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': contableAccountRequiredErrors.DESCRIPCION }"
        :message="{
          'La descripción de la cuenta no es valida':
            contableAccountRequiredErrors.DESCRIPCION,
        }"
      >
        <b-input
          placeholder="Ejemplo:ACUMULACION DEL AJUSTE ANUAL INFLACIONARIO"
          expanded
          icon="numeric-2-circle-outline"
          v-model="newContableAccount.DESCRIPCION"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Acumula (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': contableAccountRequiredErrors.ACUMULA }"
        :message="{
          'El acumula de la cuenta no es valido':
            contableAccountRequiredErrors.ACUMULA,
        }"
      >
        <b-input
          placeholder="Ejemplo: 60000900000"
          expanded
          icon="numeric-3-circle-outline"
          v-model="newContableAccount.ACUMULA"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Descripción (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': contableAccountRequiredErrors.TIPO }"
        :message="{
          'El tipo de la cuenta no es valido':
            contableAccountRequiredErrors.TIPO,
        }"
      >
        <b-input
          placeholder="Ejemplo: L"
          expanded
          icon="numeric-4-circle-outline"
          v-model="newContableAccount.TIPO"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Estado (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': contableAccountRequiredErrors.ESTADO }"
        :message="{
          'El estado de la cuenta no es valido':
            contableAccountRequiredErrors.ESTADO,
        }"
      >
        <b-input
          placeholder="Ejemplo: 0"
          expanded
          icon="numeric-5-circle-outline"
          v-model="newContableAccount.ESTADO"
          required
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls" v-if="contableAccountInformation">
      <b-button type="is-success" @click="saveContableAccount(1)"
        >Actualizar cuenta contable</b-button
      >
    </div>

    <div class="add-global-controls" v-if="!contableAccountInformation">
      <b-button type="is-success" @click="saveContableAccount(0)"
        >Guardar cuenta contable</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import { fillObjectForm } from "../../../utils/component-fns";
// @ is an alias to /src
export default {
  name: "AddContableAccount",
  props: ["contableAccountInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      newContableAccount: {
        CUENTA: "",
        DESCRIPCION: "",
        ACUMULA: "",
        TIPO: "",
        ESTADO: "",
      },
      contableAccountRequiredErrors: {
        CUENTA: false,
        DESCRIPCION: false,
        ACUMULA: false,
        TIPO: false,
        ESTADO: false,
      },
    };
  },
  mounted() {
    // Fill the brand information in form fields
    if (this.contableAccountInformation) {
      // Fill the form with received information
      fillObjectForm(this.contableAccountInformation, this.newContableAccount);
      this.newContableAccount._id = this.contableAccountInformation._id;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves or updates a new brand depending on the type
     */
    async saveContableAccount(type) {
      try {
        this.contableAccountRequiredErrors = validateFields(
          this.newContableAccount,
          this.contableAccountRequiredErrors
        );
        if (checkValidationErrors(this.contableAccountRequiredErrors)) {
          let response;

          if (type === 0) {
            response = await this.$store.dispatch("SAVECONTABLEACCOUNT", {
              contableAccounts: [this.newContableAccount],
            });
          } else {
            response = await this.$store.dispatch(
              "EDITCONTABLEACCOUNT",
              this.newContableAccount
            );
          }

          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la cuenta",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (type === 0) this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar la cuenta en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la cuenta en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar la cuenta en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
