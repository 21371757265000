/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Configurar guia contable de
      <span class="global-form-hint-color"
        >{{ contableGuidelineInformation.NOMBRE }}
      </span>
    </h1>

    <b-field grouped class="special-margin">
      <b-field
        label="Formato de las cuentas contables separado por - (Requerido)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 3-3-5 Es igual a 000-000-00000 solo poner 3-3-5"
          expanded
          icon="numeric-1-circle-outline"
          v-model="contableGuidelineInformation.FORMATO_CUENTAS_CONTABLES"
          required
        ></b-input>
      </b-field>
    </b-field>

    <div class="invoice-box special-margin">
      <div class="table-header special-margin">
        Cantidad de reglas en guia contables:
        <b>{{ contableGuidelineInformation.REGLAS.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="contableGuidelineInformation.REGLAS"
        :striped="true"
        :bordered="true"
        scrollable
        ref="contableGuidelineDetails"
      >
        <template #empty>
          <div class="has-text-centered">No hay guias contables</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Regla activada" field="estado">
            <b-checkbox v-model="props.row.ESTADO" />
          </b-table-column>

          <b-table-column label="Cuenta" field="CUENTA">
            <b-autocomplete
              placeholder="Cuenta contable"
              :data="autoCompleteContableAccount(props.row.NOMBRE_CUENTA)"
              v-model="props.row.NOMBRE_CUENTA"
              field="DESCRIPCION"
              autocomplete
              icon="text-search"
              open-on-focus
              expanded
              clearable
              @select="
                (option) => {
                  props.row.CUENTA = option.CUENTA;
                }
              "
              check-infinite-scroll
            >
              <template #empty>No se encontraron proveedores</template>
            </b-autocomplete>
          </b-table-column>

          <b-table-column label="Descripción" field="DESCRIPCION">
            <span>{{ props.row.DESCRIPCION }}</span>
          </b-table-column>

          <b-table-column label="Tipo" field="TIPO">
            <span>{{ props.row.TIPO }}</span>
          </b-table-column>

          <b-table-column label="Campo" field="CAMPO">
            <span>{{ props.row.CAMPO }}</span>
          </b-table-column>
        </template>
      </b-table>
    </div>
    <div class="add-global-controls">
      <b-button type="is-success" @click="updateContableGuideline()"
        >Guardar configruación de guia contable</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ConfigureContableGuidelines",
  components: {},
  directives: { cleave },
  props: ["contableGuidelineInformation"],
  data() {
    return {
      selected: {},
      masks: Masks,
      moment: moment,
      refreshLoading: false,
      messageError: "",
    };
  },
  async created() {
    moment.locale("es");
  },
  async mounted() {},
  methods: {
    autoCompleteContableAccount(value) {
      if (!this.contableAccounts) {
        return [];
      }
      if (!value) {
        return this.contableAccounts;
      }
      return this.contableAccounts.filter((singleAccount) => {
        return (
          singleAccount.CUENTA.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0 ||
          singleAccount.DESCRIPCION.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    closeModal() {
      this.$emit("close");
    },
    validateConfiguration() {
      if (!this.contableGuidelineInformation.FORMATO_CUENTAS_CONTABLES) {
        this.messageError = "Revise el formato de las cuentas contables";
        return false;
      }

      for (const singleRule of this.contableGuidelineInformation.REGLAS) {
        if (singleRule.ESTADO && !singleRule.CUENTA) {
          this.messageError = "Revise las reglas de la guia contable";
          return false;
        }
      }

      this.messageError = "";

      return true;
    },
    async updateContableGuideline(type) {
      try {
        if (this.validateConfiguration()) {
          let response = await this.$store.dispatch(
            "EDITCONTABLEGUIDELINE",
            this.contableGuidelineInformation
          );

          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la configuración",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la configuración en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la configuración en la base de datos revise las cuentas de las reglas activas. Error: ${this.messageError}`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la configuración en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    contableAccounts() {
      return this.$store.getters.CONTABLEACCOUNTS.map(
        (singleContableAccount) => {
          let singleContableAccountWithProps = { ...singleContableAccount };

          return singleContableAccountWithProps;
        }
      );
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
