<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Generar poliza de
      <span class="global-form-hint-color">{{ polizaName }} </span>
    </h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Fecha de inicio (Requerido)"
        label-position="inside"
        expanded
      >
        <b-datepicker
          class="global-lists-filters-control margin required-input"
          @input="(value) => changeDateFilterValue(value)"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          :show-week-number="true"
          locale="es-MX"
          placeholder="Fecha de inicio"
          icon="calendar-today"
          v-model="polizaFilters.startDate"
          trap-focus
        >
        </b-datepicker>
      </b-field>
      <b-field
        label="Fecha de fin (Requerido)"
        label-position="inside"
        expanded
      >
        <b-datepicker
          class="global-lists-filters-control required-input"
          @input="(value) => changeDateFilterValue(value)"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          :show-week-number="true"
          locale="es-MX"
          placeholder="Fecha de fin"
          icon="calendar-today"
          v-model="polizaFilters.endDate"
          trap-focus
        >
        </b-datepicker>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin required-input">
      <b-field label="Almacenes (Requerido)" expanded label-position="inside">
        <b-taginput
          :data="warehouses"
          autocomplete
          :allow-new="false"
          :open-on-focus="true"
          field="NOMBRE_ALM"
          icon="warehouse"
          v-model="polizaFilters.selectedWarehouses"
          @add="(value) => addWarehouse(value)"
          @remove="(value) => removeWarehouse(value)"
          ellipsis
          placeholder="Nombre de alamacen"
          aria-close-label="Borrar almacen seleccionado"
          check-infinite-scroll
        >
        </b-taginput>
      </b-field>
    </b-field>

    <h3 class="form-division">Forma de división de poliza</h3>

    <b-checkbox
      class="permissions-checkbox first-row-checkbox special-margin"
      v-if="objectFilter"
      v-model="polizaFilters.objectFilter"
      @input="(value) => handleCheckboxFilter(value, 'objectFilter')"
      :disabled="disableCheckboxFilters && !polizaFilters.objectFilter"
    >
      {{ objectFilterName }}
    </b-checkbox>

    <b-checkbox
      class="permissions-checkbox first-row-checkbox special-margin"
      v-if="dayFilter"
      v-model="polizaFilters.dayFilter"
      @input="(value) => handleCheckboxFilter(value, 'dayFilter')"
      :disabled="disableCheckboxFilters && !polizaFilters.dayFilter"
    >
      Día
    </b-checkbox>

    <div class="add-global-controls">
      <b-button type="is-success" @click="generatePoliza(0)"
        >Generar poliza</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";

// @ is an alias to /src
export default {
  name: "ProvisionComprasFilter",
  props: ["polizaName", "objectFilter", "dayFilter", "objectFilterName"],
  components: {},
  directives: { cleave },
  data() {
    return {
      moment: moment,
      polizaFilters: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ),
        selectedWarehouses: [],
        objectFilter: false,
        dayFilter: false,
      },
      errorMessage: "",
      disableCheckboxFilters: false,
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    validatePolizaGeneration() {
      if (
        moment(this.polizaFilters.startDate).isAfter(this.polizaFilters.endDate)
      ) {
        this.errorMessage = "La fecha de inicio no es válida";
        return false;
      }

      if (
        moment(this.polizaFilters.endDate).isBefore(
          this.polizaFilters.startDate
        )
      ) {
        this.errorMessage = "La fecha de fin no es válida";
        return false;
      }

      if (!this.polizaFilters.selectedWarehouses.length) {
        this.errorMessage = "Los almacenes no son validos";
        return false;
      }

      if (!this.polizaFilters.objectFilter && !this.polizaFilters.dayFilter) {
        this.errorMessage = "La forma de división de poliza no es valida";
        return false;
      }

      this.errorMessage = "";
      return true;
    },
    /**
     * @desc saves or updates a new brand depending on the type
     */
    async generatePoliza() {
      try {
        if (this.validatePolizaGeneration()) {
          let response = await this.$store.dispatch("GENERATEPOLIZA", {
            filters: this.polizaFilters,
            polizaName: this.polizaName,
          });

          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se generó exitosamente la poliza",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al generar la poliza",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al generar la poliza revise los campos obligatorio error: ${this.errorMessage}`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al generar la poliza",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    addWarehouse(warehouse) {
      this.polizaFilters.selectedWarehouses.push(warehouse._id);
    },
    removeWarehouse(warehouse) {
      for (const [
        singleWarehouseIndex,
        singleWarehouse,
      ] of this.polizaFilters.selectedWarehouses.entries()) {
        if (singleWarehouse === warehouse._id) {
          this.polizaFilters.selectedWarehouses.splice(singleWarehouseIndex, 1);
          return;
        }
      }
    },
    handleCheckboxFilter(value, filterType) {
      console.log(value);

      if (value) {
        this.polizaFilters.objectFilter = false;
        this.polizaFilters.dayFilter = false;
        this.polizaFilters[filterType] = value;

        this.disableCheckboxFilters = true;
      } else {
        this.disableCheckboxFilters = false;
      }
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Configuration/style/Configuration.css";
</style>
