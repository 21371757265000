var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[(!_vm.contableAccountInformation)?_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v(" Agregar Cuenta Contable ")]):_vm._e(),(!_vm.contableAccountInformation)?_c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado ")])]):_vm._e(),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Cuenta (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.contableAccountRequiredErrors.CUENTA },"message":{
        'La clave de la cuenta no es valida':
          _vm.contableAccountRequiredErrors.CUENTA,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 60000900002","expanded":"","icon":"numeric-1-circle-outline","required":""},model:{value:(_vm.newContableAccount.CUENTA),callback:function ($$v) {_vm.$set(_vm.newContableAccount, "CUENTA", $$v)},expression:"newContableAccount.CUENTA"}})],1),_c('b-field',{attrs:{"label":"Descripción (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.contableAccountRequiredErrors.DESCRIPCION },"message":{
        'La descripción de la cuenta no es valida':
          _vm.contableAccountRequiredErrors.DESCRIPCION,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo:ACUMULACION DEL AJUSTE ANUAL INFLACIONARIO","expanded":"","icon":"numeric-2-circle-outline","required":""},model:{value:(_vm.newContableAccount.DESCRIPCION),callback:function ($$v) {_vm.$set(_vm.newContableAccount, "DESCRIPCION", $$v)},expression:"newContableAccount.DESCRIPCION"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Acumula (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.contableAccountRequiredErrors.ACUMULA },"message":{
        'El acumula de la cuenta no es valido':
          _vm.contableAccountRequiredErrors.ACUMULA,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 60000900000","expanded":"","icon":"numeric-3-circle-outline","required":""},model:{value:(_vm.newContableAccount.ACUMULA),callback:function ($$v) {_vm.$set(_vm.newContableAccount, "ACUMULA", $$v)},expression:"newContableAccount.ACUMULA"}})],1),_c('b-field',{attrs:{"label":"Descripción (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.contableAccountRequiredErrors.TIPO },"message":{
        'El tipo de la cuenta no es valido':
          _vm.contableAccountRequiredErrors.TIPO,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: L","expanded":"","icon":"numeric-4-circle-outline","required":""},model:{value:(_vm.newContableAccount.TIPO),callback:function ($$v) {_vm.$set(_vm.newContableAccount, "TIPO", $$v)},expression:"newContableAccount.TIPO"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Estado (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.contableAccountRequiredErrors.ESTADO },"message":{
        'El estado de la cuenta no es valido':
          _vm.contableAccountRequiredErrors.ESTADO,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 0","expanded":"","icon":"numeric-5-circle-outline","required":""},model:{value:(_vm.newContableAccount.ESTADO),callback:function ($$v) {_vm.$set(_vm.newContableAccount, "ESTADO", $$v)},expression:"newContableAccount.ESTADO"}})],1)],1),(_vm.contableAccountInformation)?_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveContableAccount(1)}}},[_vm._v("Actualizar cuenta contable")])],1):_vm._e(),(!_vm.contableAccountInformation)?_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveContableAccount(0)}}},[_vm._v("Guardar cuenta contable")]),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }