<template>
  <div class="container add-global-modal-main-container import-modal">
    <h1 class="add-global-modal-main-title">Importar {{ nameImport }}</h1>
    <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
    <span class="global-form-hint-color"> Recuerda: </span> Importar información
    al sistema puede tardar varios minutos en lo que es subida, puedes utilizar
    otras secciones del sistema en lo que este proceso termina, estos nuevos
    elementos solo seran visibles al refresar el sitio, por lo que debe
    refrescar el sitio una vez pasado algunos minutos.
    <strong
      >Asegurate que el contenido del archivo es el deseado antes de continuar
      con la importación.</strong
    >
    <b-field>
      <b-upload
        v-model="file"
        :multiple="false"
        drag-drop
        expanded
        required
        accept=".txt"
        class="import-input"
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"></b-icon>
            </p>
            <p>Arraste su archivo o haga click y seleccione su archivo</p>
          </div>
        </section>
      </b-upload>
    </b-field>
    <div class="tags absolut-center-row">
      <span class="tag is-primary" v-if="file">
        {{ file.name }}
        <button
          class="delete is-small"
          type="button"
          @click="deleteInformation()"
        ></button>
      </span>
    </div>
    <div class="add-import-controls">
      <b-button
        type="is-success"
        @click="uploadFile()"
        :disabled="!file"
        class="secondary-button"
        >Importar</b-button
      >
    </div>
  </div>
</template>

<script>
// Cleave variable initialization to bind formats to inputs
import moment from "moment";
import { EventBus } from "../../../event-bus";
import Cleave from "cleave.js";

const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

// @ is an alias to /src
export default {
  name: "ImportFileTxtModal",
  components: {},
  directives: { cleave },
  props: ["action", "importType", "nameImport"],
  data() {
    return {
      file: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc uploads new information to the database
     */
    async uploadFile() {
      try {
        if (this.file) {
          const importData = {
            file: this.file,
            dataType: this.importType,
          };

          let formatresponse = await this.$store.dispatch(
            "IMPORTLOCALTXT",
            importData
          );

          this.$store.commit("SET_LOADING", true);

          let response = await this.$store.dispatch(
            this.action,
            formatresponse
          );

          if (response === "Success") {
            this.$store.commit("SET_LOADING", false);
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message:
                "Hemos recibido con éxito la información!! Seguimos trabajando para importarla, se te notificará una vez terminado el proceso. Este proceso por lo regular tarda un par de minutos, mientras tanto puedes seguir disfrutando de tu experiencia! 😃",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al importar la información",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            this.$store.commit("SET_LOADING", false);
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al importar la información",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.$store.commit("SET_LOADING", false);
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al importar la información",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        this.$store.commit("SET_LOADING", false);
      }
    },
    deleteInformation() {
      this.file = null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Global/style/ImportModal.css";
</style>
